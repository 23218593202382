export default {
  props: ["nameScreen"],
  data() {
    return {
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.articleTexts[this.selectLanguage];
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    iTab(){
      return this.$store.state.iTab;
    }
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.articleTexts[this.selectLanguage];
      }
    },
  },
};